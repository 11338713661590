import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';

import { RootState } from '../../../Shared/js/redux/rootReducer';
import type { SearchCriteria } from '../@types/SearchCriteria';

export interface ModalSliceState {
    activeTab: string;
    isOpen: boolean;
    criteria: SearchCriteria;
}

const initialState = {} as ModalSliceState;

const modalSlice = createSlice({
    name: 'broadband/modal',
    initialState,
    reducers: {
        showModal(state, action: PayloadAction<{ criteria: SearchCriteria; tabName: string }>) {
            // Should we just select the first one by default?
            state.activeTab = action.payload.tabName || 'spend';
            
            state.criteria = produce(action.payload.criteria, draft => {
                return draft;
            });
            state.isOpen = true;
        },

        hideModal(state) {
            state.isOpen = false;

            // Reset search criteria
            state.criteria = {} as SearchCriteria;
        },

        selectTab(state, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        },

        updateCriteria(state, action: PayloadAction<SearchCriteria>) {
            if (isEqual(state.criteria, action.payload)) {
                return;
            }
            state.criteria = action.payload;
        },

        updateData(state, action: PayloadAction<number>) {
            state.criteria.data = action.payload;
        },

        updatePeakDataOnly(state, action: PayloadAction<boolean>) {
            state.criteria.peakDataOnly = action.payload;
        },

        updateSpeed(state, action: PayloadAction<number>) {
            state.criteria.speed = action.payload;
        },

        updateSpend(state, action: PayloadAction<{ min: number; max: number }>) {
            const { min, max } = action.payload;
            state.criteria.common.minimumSpend = min;
            state.criteria.common.maximumSpend = max;
        },

        updateUpfront(state, action: PayloadAction<number>) {
            state.criteria.common.maximumUpfront = action.payload;
        },

        updateSelectedSuppliers(state, action: PayloadAction<{ keys: string[]; customerType: string }>) {
            const { keys, customerType } = action.payload;
            state.criteria.common.suppliers.values = keys;
            state.criteria.common.customerType = customerType;
        },

        updateCustomerType(state, action: PayloadAction<string>) {
            state.criteria.common.customerType = action.payload;
        },

        updateContractTerms(state, action: PayloadAction<{ selected: number[]; all: number[] }>) {
            const { selected, all } = action.payload;
            state.criteria.common.contractTerms = selected && selected.length === all.length ? [] : selected;
        },

        updateAdvancedOptions(state, action: PayloadAction<string[]>) {
            const options = action.payload;
            state.criteria.common.maxResultsPerSupplier = includes(options, 'single-result') ? 1 : null;
            state.criteria.staticIp = includes(options, 'static-ip');
            state.criteria.uploadNotCounted = includes(options, 'upload-not-counted');
            state.criteria.common.showLessResults = includes(options, 'less-results');
        },

        updatePrepaidExpiry(state, action: PayloadAction<number>) {
            state.criteria.prepaidExpiry = action.payload;
        },

        updateCurrentSupplier(state, action: PayloadAction<string>) {
            state.criteria.common.currentSupplierShortUrl = action.payload;
        },

        updateIncludeDealsOnly(state, action: PayloadAction<boolean>) {
            state.criteria.common.includeOffersWithCampaignOnly = action.payload;
        },

        updatePhoneBundle(state, action?: PayloadAction<boolean>) {
            state.criteria.bundles.homePhone = action.payload;
        },

        updatePhoneCallsBundle(state, action: PayloadAction<number[]>) {
            state.criteria.bundles.homePhoneCalls = action.payload;
        },

        updateTvBundle(state, action?: PayloadAction<boolean>) {
            state.criteria.bundles.tv = action.payload;
        },

        updateTvChannels(state, action: PayloadAction<number[]>) {
            state.criteria.bundles.tvChannels = action.payload;
        },

        updateLineRentalBundle(state, action?: PayloadAction<boolean>) {
            state.criteria.bundles.lineRental = action.payload;
        },

        updateMobileBundle(state, action?: PayloadAction<boolean>) {
            state.criteria.bundles.mobilePhone = action.payload;
        },

        updateModem(state, action: PayloadAction<{ modemShortUrl: string; simOnly: boolean }>) {
            const { modemShortUrl, simOnly } = action.payload;
            state.criteria.modemShortUrl = modemShortUrl;
            state.criteria.simOnly = simOnly;
        },

        updateConnectionTypes(state, action: PayloadAction<string[]>) {
            state.criteria.connectionTypes.values = action.payload;
        },

        addressChanged(state) {
            // Reset suppliers and bundles criteria
            state.criteria.common.suppliers = {
                values: []
            };
            state.criteria.common.customerType = null;
            state.criteria.bundles = {} as SearchCriteria['bundles'];
        }
    }
});

export default modalSlice.reducer;
export const actions = modalSlice.actions;

export const selectModal = createSelector(
    (state: RootState) => state?.broadband,
    p => p.modal
);
