export function appendQueryParam(queryString, param) {
    var value = queryString;
    if (value.length > 0) value += '&';

    value += param;
    return value;
}

export function updateQueryStringParameter(url, key, value) {
    if (url == null) return null;
    if (key == null) return url;

    var regex = new RegExp('(' + key + '=[^&]*)&?', 'i');
    var separator = url.indexOf('?') !== -1 ? '&' : '?';
    var result;
    if (url.match(regex)) {
        if (value === null || typeof value === 'undefined' || value.length === 0) {
            result = url.replace(regex, '').replace(/&$/, '');
        } else {
            result = url.replace(regex, key + '=' + value + '&').replace(/&$/, '');
        }
    }
    else {
        if (value === null || typeof value === 'undefined' || value.length === 0) {
            result = url;
        } else {
            result = url + separator + key + '=' + value;
        }
    }

    return result;
}

export function getQueryStringParameter(name, url) {
    if (!url) url = window.location.href;
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('(^|[?&])' + name + '(=([^&#]*)|&|#|$)');

    var matches = regex.exec(url);
    if (!matches) return null;

    var match = matches[3];
    if (!match) return '';
    return decodeURIComponent(match.replace(/\+/g, ' '));
}