import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { SearchResult } from '../@types/api/SearchResult';

export const broadbandApi = createApi({
    reducerPath: 'broadband/api',
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: builder => ({
        getSearchResults: builder.query<SearchResult, { baseUrl: string; queryString: string }>({
            query: ({ baseUrl, queryString }) => {
                return queryString.length ? `${baseUrl}?${queryString}` : baseUrl;
            }
        }),
        getTransactUrl: builder.query<
            { transactUrl: string },
            { supplierShortUrl: string; productShortUrl: string; address: string }
        >({
            query: args => {
                const queryParams = new URLSearchParams(args);
                return `/Ajax/Broadband/Product/GetTransactUrl/?${queryParams.toString()}`;
            }
        })
    })
});

export type BroadbandApiState = Parameters<typeof broadbandApi.reducer>['0'];
