// See: https://developer.mozilla.org/en-US/docs/Web/API/Background_Tasks_API#Concepts_and_usage -> Falling back to setTimeout

export function init() {
    if (!window.requestIdleCallback) {
        window.requestIdleCallback =
            window.requestIdleCallback ||
            function (handler) {
                const startTime = Date.now();

                return setTimeout(function () {
                    handler({
                        didTimeout: false,
                        timeRemaining: function () {
                            return Math.max(0, 50.0 - (Date.now() - startTime));
                        }
                    });
                }, 1) as unknown as number;
            };
    }

    if (!window.cancelIdleCallback) {
        window.cancelIdleCallback =
            window.cancelIdleCallback ||
            function (id) {
                clearTimeout(id);
            };
    }
}
