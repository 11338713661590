type WaitForArgs = {
    /** Periodic interval in milliseconds to call condition check */
    intervalMs?: number;
    /** Timeout in milliseconds */
    timeoutMs?: number;
    timeoutMessage?: string;
    onTimeout?: (e: Error) => void;
};

function initWaitForArgs(source: WaitForArgs) {
    // Set defaults
    return Object.assign(
        <WaitForArgs>{
            intervalMs: 50,
            timeoutMs: 5000,
            timeoutMessage: 'Timeout on waiting for condition',
            onTimeout: e => console.warn(e)
        },
        source
    );
}

/**
 * Awaits for condition
 * @param condition condition check function
 * @param args
 * @throws Will throw error on timeout
 */
export function waitFor(condition: () => boolean, args?: WaitForArgs) {
    args = initWaitForArgs(args);

    return new Promise<void>((resolve, reject) => {
        const onResolve = () => {
            // console.debug(`Condition met, location: ${window.location}`);
            resolve();
        };

        if (condition()) {
            onResolve();
            return;
        }

        let cancel = false;
        const interval = setInterval(() => {
            // console.debug(`Checking condition, location: ${window.location}`);
            if (!condition()) {
                return;
            }

            clearInterval(interval);
            cancel = true;
            onResolve();
        }, args.intervalMs);

        setTimeout(() => {
            clearInterval(interval);
            if (cancel) {
                return;
            }

            // Don't create the error immediately to avoid it being logged incorrectly
            const error = () => new Error(args.timeoutMessage);
            reject(error());

            if (args.onTimeout) {
                args.onTimeout(error());
            }
        }, args.timeoutMs);
    });
}

/**
 * Awaits for condition wihtout throwing timeout error
 * @param condition condition check function
 * @param args
 */
export async function tryWaitFor(condition: () => boolean, args?: WaitForArgs) {
    args = initWaitForArgs(args);
    try {
        await waitFor(condition, args);
    } catch (error) {
        if (error.message === args.timeoutMessage && args.onTimeout) {
            args.onTimeout(error);
            return;
        }

        WhistleOut.Rollbar.warning(error);
    }
}
