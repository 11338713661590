import type rollbarType from 'rollbar';

export const rollbar = {
    instance: <rollbarType>{},
    config: <
        {
            env: string;
            token: string;
            captureIp: boolean;
        }
    >{
        captureIp: false
    },
    async init() {
        const Rollbar = (await import('rollbar')).default;

        this.instance = new Rollbar({
            accessToken: this.config.token,
            captureUncaught: false,
            captureUnhandledRejections: false,
            captureIp: this.config.captureIp,
            payload: {
                environment: this.config.env
            }
        });

        this.instance.configure({
            scrubFields: ['apiKey', 'password']
        });
    }
};
