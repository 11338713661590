import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Address } from '../@types/Address';

const sharedSlice = createSlice({
    name: 'shared',
    initialState: {
        addressCookie: {
            initialiesdWith: null
        }
    },
    reducers: {
        addressCookieInit(state, action: PayloadAction<{ address: Address }>) {
            state.addressCookie.initialiesdWith = action.payload.address;
        }
    }
});

export default sharedSlice.reducer;
export const actions = sharedSlice.actions;
