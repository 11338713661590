import once from 'lodash/once';

import { waitFor } from '../../utils/timer';

const isInitialised = () => {
    return !!wo$.fn.selectpicker;
};

export const initBootstrapSelect = once(async () => {
    if (isInitialised()) {
        return;
    }

    await import('./bootstrap-select.nomodule');
    await waitFor(isInitialised);

    const context = wo$.fn.selectpicker.Constructor;
    context.BootstrapVersion = '3.3.6';
    context.DEFAULTS.iconBase = 'fa';
    context.DEFAULTS.tickIcon = 'fa-check';
});
