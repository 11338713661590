import { combineReducers } from 'redux';

import { broadbandApi } from '../../../Broadband/js/redux/broadbandApi';
import broadband from '../../../Broadband/js/redux/broadbandSlice';
import shared from './sharedSlice';

const features = {
    broadband,
    shared
};

const apis = {
    // Unable to declare API in the broadband slice directly,
    // because 'state.' prefix is added causing incorrect state name, i.e. 'state.broadband/api'.
    [broadbandApi.reducerPath]: broadbandApi.reducer
};

const rootReducer = combineReducers({
    ...features,
    ...apis
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
