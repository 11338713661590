// TODO: Enable eslint rules and fix the code
/* eslint-disable eqeqeq */

export class WhistleOutCore {
    showSiteNotice() {
        try {
            const siteNotice = wo$('#siteNotice');
            const divWrapper = siteNotice.children().first();
            if (divWrapper && divWrapper.length > 0) {
                const key = divWrapper.data('cookie-key') || 'SiteNotice';
                const expirationInHours = divWrapper.data('cookie-expiry');
                let slideTime = divWrapper.data('slide-duration');

                if (slideTime) slideTime = 300;

                let expirationDate: Date;
                if (expirationInHours && expirationInHours > 0) {
                    expirationDate = new Date();
                    expirationDate.setTime(expirationDate.getTime() + expirationInHours * 60 * 60 * 1000);
                } else {
                    expirationDate = null;
                }
                if (Cookies.get(key) == undefined) {
                    const closeButton = siteNotice.find('#siteNoticeClose');
                    if (closeButton && closeButton.length > 0) {
                        if (slideTime == 0) siteNotice.toggle();
                        else siteNotice.slideToggle(slideTime);

                        closeButton.click(function () {
                            siteNotice.slideToggle(slideTime);
                            Cookies.set(key, '', { path: '/', expires: expirationDate });
                        });
                    } else {
                        if (slideTime == 0) siteNotice.toggle();
                        else siteNotice.slideToggle(slideTime);

                        Cookies.set(key, '', { path: '/', expires: expirationDate });
                    }
                }
            }
        } catch (e) {
            // TODO: Use 'instanceof' to only ignore errors of a certain type, and re-throw others
        }
    }

    bindExpressNotice() {
        wo$('#expressDisable').click(function () {
            const config = WhistleOut.getSiteConfiguration();
            Cookies.remove(config.cookieName, {
                path: '/',
                domain: config.cookieDomain
            });
            if (location.href.indexOf('adi=') === -1) {
                location.reload();
            } else {
                const url = WhistleOut.updateQueryStringParameter(location.href, 'adi', null);
                location.href = url;
            }
        });
    }

    bindTopNav() {
        wo$('#site-search-button').on('click', e => {
            wo$(e.currentTarget).hide();
            wo$('#site-search-form').show();
            if (wo$('#site-search-form').is(':visible')) {
                wo$('#site-search-query').focus();
            }
        });

        wo$('#site-search-icon').on('click', () => {
            wo$('#site-search-form').hide();
            wo$('#site-search-button').show();
        });

        wo$('#site-search-query, #site-search-query-2').on('keydown', e => {
            if (e.which === 13) {
                WhistleOut.Core.search(wo$(e.currentTarget).val() as string);
                return false;
            }
        });

        wo$('#search-term').on('keydown', e => {
            const target = wo$(e.currentTarget);
            if (e.which === 13 && target.val() && (target.val() as string).length) {
                WhistleOut.Core.search(target.val() as string);
                return false;
            }
        });

        wo$('#search-term-button').on('click', () => {
            if (wo$('#search-term').val() && (wo$('#search-term').val() as string).length) {
                WhistleOut.Core.search(wo$('#search-term').val() as string);
            }
            return false;
        });

        // Most likely based on https://stackoverflow.com/questions/36414922/how-to-keep-the-bootstrap-pop-over-enable-until-the-form-is-filled/36421312#36421312
        // Example: https://jsfiddle.net/sfw36go1/
        const popoverProto = () =>
            wo$.fn.popover as unknown as {
                Constructor(element: JQuery<HTMLElement>, options: Bootstrap.PopoverOptions): void;
            };

        const originalLeave = popoverProto().Constructor.prototype.leave;
        popoverProto().Constructor.prototype.leave = function (obj: JQuery.TriggeredEvent) {
            const self =
                obj instanceof this.constructor
                    ? obj
                    : wo$(obj.currentTarget)
                        [this.type](this.getDelegateOptions())
                        .data('bs.' + this.type);

            let container: JQuery<HTMLElement>;
            let timeout: NodeJS.Timeout;

            originalLeave.call(this, obj);

            if (obj.currentTarget) {
                container = wo$(obj.currentTarget).siblings('.popover');
                timeout = self.timeout;
                container.one('mouseenter', function () {
                    clearTimeout(timeout);
                    container.one('mouseleave', function () {
                        popoverProto().Constructor.prototype.leave.call(self, self);
                    });
                });
            }
        };

        const nav = wo$('#navbar');
        nav.find('a.dropdown-toggle').on('click', e => {
            const target = wo$(e.currentTarget);
            if (!nav.hasClass('in') && target.siblings('ul.dropdown-menu').is(':visible')) {
                const href = target.attr('href');
                location.href = href;
            }
        });

        wo$('#navbar').on('click', '#site-search-button-2', function () {
            const query = wo$('#site-search-query-2').val() as string;
            WhistleOut.Core.search(query);
        });
    }

    search(query) {
        const url = wo$('#site-search').data('url');
        window.location.href = url + '?q=' + encodeURIComponent(query);
    }

    checkCountry() {
        try {
            if (
                (Cookies.get('woCountrySelector') == null || typeof Cookies.get('woCountrySelector') === 'undefined') &&
                document.referrer.indexOf('.whistleout.') === -1
            ) {
                Cookies.set('woCountrySelector', '', { path: '/' });
                const config = WhistleOut.getSiteConfiguration();
                if (config.enableGeoCheck) {
                    wo$.ajax({
                        url: 'https://www.whistleout.com.au/cdn-cgi/trace',
                        type: 'GET',
                        success: function (result) {
                            const matches = result.match('loc=(.{2})');
                            if (matches && matches.length === 2) {
                                let country = 0;
                                switch (matches[1]) {
                                    case 'AU':
                                        country = 1;
                                        break;
                                    case 'US':
                                        country = 3;
                                        break;
                                    case 'CA':
                                        country = 5;
                                        break;
                                    case 'MX':
                                        country = 8;
                                        break;
                                }
                                if (country > 0 && country !== config.country) {
                                    WhistleOut.Core.showCountrySwitcher(country);
                                }
                            }
                        }
                    });
                }
            }
        } catch (e) {
            // TODO: Use 'instanceof' to only ignore errors of a certain type, and re-throw others
        }
    }

    showCountrySwitcher(country) {
        try {
            const data = {
                country: country
            };
            wo$.ajax({
                url: '/Layout/CountrySwitcher',
                data: data,
                type: 'GET',
                success: function (result) {
                    if (result !== '') {
                        wo$('#country-switcher-container').html(result);
                        wo$('#country-switcher-modal').modal('show');
                    }
                }
            });
        } catch (e) {
            // TODO: Use 'instanceof' to only ignore errors of a certain type, and re-throw others
        }
    }

    bindLinkStrip() {
        const strip = wo$('#suggested-articles');
        strip.affix({
            offset: { top: 400 }
        });

        wo$('#suggested-articles-close').on('click', function () {
            const expires = new Date();
            expires.setTime(expires.getTime() + 60 * 60 * 1000);
            Cookies.set('ShowStickyBottomStrip', '0', { path: '/', expires: expires });
            strip.hide();
        });
    }

    bindAdminLinks() {
        let timeout: NodeJS.Timeout;
        wo$('#admin-links').hover(
            e => {
                clearTimeout(timeout);
                const target = wo$(e.currentTarget);
                target.animate(
                    {
                        height: target.get(0).scrollHeight,
                        opacity: 1
                    },
                    250
                );
            },
            e => {
                const target = wo$(e.currentTarget);
                timeout = setTimeout(() => {
                    target.animate(
                        {
                            height: '30px',
                            opacity: 0.7
                        },
                        250
                    );
                }, 1000);
            }
        );

        wo$('#admin-purge-cache').click(function () {
            if (confirm('Are you sure you want to purge this page from the cache?')) {
                const data = {
                    url: location.href
                };
                wo$.ajax({
                    url: '/Internal/Shared/Infrastructure/PurgeCacheUrl',
                    type: 'POST',
                    data: data,
                    success: function () {
                        alert('Success!');
                    },
                    error: function () {
                        alert('An error has occurred purging this URL from the cache');
                    }
                });
            }
        });
    }

    init() {
        wo$(() => {
            this.showSiteNotice();
            this.bindExpressNotice();
            this.bindAdminLinks();
            this.bindTopNav();
            this.bindLinkStrip();
        });
    }
}
