import type { DataLayerItemWidget, WoDataLayerItem } from '../@types/WoDataLayer';
import { onArrayPush } from './array';

export function pushDataLayerItem(item: WoDataLayerItem) {
    window.woDataLayer = window.woDataLayer || [];
    window.woDataLayer.push(item);
}

export function pushDataLayerItemWidget(item: DataLayerItemWidget) {
    pushDataLayerItem(item);
}

function getWidgetType() {
    const pathName = window.location.pathname;
    const matches = /^\/widgets\/(\w+)([/|?].+)?/i.exec(pathName);
    return matches ? matches[1] : undefined;
}

export function initDataLayer() {
    window.woDataLayer = window.woDataLayer ?? [];

    // Set default values, reset unused (not explicitly set) parameters
    onArrayPush(window.woDataLayer, items => {
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            item['duration'] ??= undefined;
            item['error'] ??= undefined;
            item['widget_type'] ??= undefined;
            item['widget_placement'] ??= undefined;

            item['widget_type'] ??= getWidgetType();
        }
    });
}
